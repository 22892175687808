import React from "react"
import { graphql } from "gatsby"
import MDXRenderer from "./elements/MyMdx"
import RenderImage from "./elements/RenderImage"
import Layout from "./Layout"
import Seo from "./Seo"
import DisplayTags from "./elements/TagList"

const Player = ({ episode }) => {
  var src = ""
  if (episode.frontmatter.libsyn_id) {
    src = `//html5-player.libsyn.com/embed/episode/id/${episode.frontmatter.libsyn_id}/height/90/theme/custom/autoplay/no/autonext/no/thumbnail/yes/preload/no/no_addthis/no/direction/forward/render-playlist/no/custom-color/000000/`
  } else {
    src = episode.frontmatter.player_url
  }
  const style = {
    border: "none",
  }
  return (
    <iframe
      style={style}
      src={src}
      height="90"
      width="100%"
      scrolling="no"
      allowfullscreen
      webkitallowfullscreen
      mozallowfullscreen
      oallowfullscreen
      msallowfullscreen
    ></iframe>
  )
}

// TODO: should i include
export default function PostLayout({ data: { mdx } }) {
  return (
    <Layout>
      <Seo
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.excerpt}
      />
      <div className="post">
        <RenderImage frontmatter={mdx.frontmatter} />
        <h1>{mdx.frontmatter.title}</h1>
        <h3>{mdx.frontmatter.subtitle}</h3>
        <span>
          {mdx.fields.date} | {mdx.timeToRead} mins reading time
        </span>
        <Player episode={mdx} />
        <ul>
          <li>
            <a href="https://itunes.apple.com/us/podcast/the-prior-transformation/id1376413788?ls=1">
              Listen on Apple Podcast
            </a>
          </li>
          <li>
            <a href="https://www.stitcher.com/podcast/the-prior-transformation">
              Listen on Stitcher
            </a>
          </li>
          <li>
            <a href="https://play.google.com/music/listen?u=0#/ps/I5d6shp6mzgk3c42qcqugsvil5i">
              Listen on Google Play
            </a>
          </li>
          <li>
            <a href="https://open.spotify.com/show/28rX7oUjEGJpXscq9wAR6b?si=Rqxi4eYkR3OfYc_L-nkE9A">
              Listen on Spotify
            </a>
          </li>
        </ul>
        <MDXRenderer>{mdx.body}</MDXRenderer>
        <div className="mt-5 py-4">
          <DisplayTags tags={mdx.frontmatter.tags} />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PodcastEpisodeQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      timeToRead
      tableOfContents
      fields {
        date
      }
      frontmatter {
        title
        image
        excerpt
        subtitle
        tags
        libsyn_id
        player_url
      }
    }
  }
`
