import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import { Link } from "gatsby"

export default ({ children }) => {
  // https://www.gatsbyjs.org/docs/mdx/customizing-components/#how-does-this-work
  const mdxComponents = {
    //h3: props => <h3 {...props} className="mt-4 mb-4 tracking-tight" />,
    //h4: props => <h4 {...props} className="mt-4 mb-4 tracking-tight" />,
    //blockquote: props => <span {...props} className="ml-15" />,
    //p: props => <p {...props} className="mt-4 mb-4 text-xl" />,
    // ul: props => <ul {...props} className="ml-10 list-disc" />,
    a: props => {
      const newProps = Object.assign({}, props)
      if (newProps.id) {
        newProps.to = newProps.id
        return <Link {...newProps} />
      }
      if (newProps.href && newProps.href[0] === "/") {
        // internal link
        newProps.to = newProps.href
        return <Link {...newProps} />
      }
      // external link
      newProps["target"] = "_new"
      return <a {...newProps} />
    },
  }
  return (
    <MDXProvider components={mdxComponents}>
      <MDXRenderer>{children}</MDXRenderer>
    </MDXProvider>
  )
}
