import React from "react"
import { Link } from "gatsby"
import { v4 as uuidv4 } from "uuid"

const DisplayTags = ({ tags }) => {
  if (!tags) {
    return null
  }
  return (
    <React.Fragment>
      {tags.map(tag => {
        return (
          <Link
            to={`/tags/${tag}/`}
            className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold italic text-gray-600 mr-2 mb-2 hover:bg-yellow-500"
            key={uuidv4()}
          >
            #{tag}
          </Link>
        )
      })}
    </React.Fragment>
  )
}

export default DisplayTags

